import { css } from '@emotion/css';
import { GrafanaTheme2, usePluginContext } from '@grafana/data';
import { config } from '@grafana/runtime';
import { IconButton, Toggletip, useStyles2 } from '@grafana/ui';
import React, { useMemo } from 'react';

// Extract version information from the package.json files
// The consequences of importing them into the source code are that the contents of these package files will be in the built result
// The pyroscope package file is publically available in open source, the plugin package file is not, but on the most part it resembles
// the boilerplate generated by Grafana's plugin scaffolding.
import { GIT_COMMIT } from '../../version';

const pluginCommitSha: string = GIT_COMMIT;
const pluginCommitURL = `https://github.com/grafana/explore-profiles/commit/${pluginCommitSha}`;

const { buildInfo } = config;

const grafanaCommitURL = `https://github.com/grafana/grafana/commit/${buildInfo.commit}`;

const getStyles = (theme: GrafanaTheme2) => ({
  list: css`
    text-align: left;

    & > dt {
      color: ${theme.colors.text.maxContrast};
      text-transform: uppercase;
    }

    & > dd {
      margin-left: ${theme.spacing(0.5)};
    }
  `,
});

export function VersionInfoTooltip() {
  const styles = useStyles2(getStyles);
  const {
    meta: {
      info: { version, updated },
    },
  } = usePluginContext();

  const versionInfo = useMemo(() => {
    const lastUpdate = new Date(updated).toLocaleString();

    return (
      <dl className={styles.list}>
        <dt>Grafana {buildInfo.edition}</dt>
        <dd>
          🔗{' '}
          <a href={grafanaCommitURL} target="_blank" rel="noopener noreferrer" title="Go to commit">
            v{buildInfo.version} ({buildInfo.env})
          </a>
        </dd>
        <dt>Plugin version</dt>
        <dd>
          v{version} - {lastUpdate}
        </dd>
        <dt>Plugin Commit SHA</dt>
        <dd>
          {pluginCommitSha === 'dev' ? (
            pluginCommitSha
          ) : (
            <>
              🔗{' '}
              <a href={pluginCommitURL} target="_blank" rel="noopener noreferrer" title="Go to commit">
                {pluginCommitSha}
              </a>
            </>
          )}
        </dd>
        <dt>Documentation</dt>
        <dd>
          <>
            🔗{' '}
            <a
              href="https://grafana.com/docs/grafana/latest/explore/simplified-exploration/profiles/"
              target="_blank"
              rel="noopener noreferrer"
              title="Explore Profiles documentation"
            >
              Explore Profiles
            </a>
          </>
        </dd>
      </dl>
    );
  }, [updated, styles.list, version]);

  return (
    <Toggletip content={versionInfo} theme="info" placement="top-start">
      <IconButton name="info-circle" aria-label="Plugin info" title="Plugin info" />
    </Toggletip>
  );
}
